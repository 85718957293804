// src/spotifyAuth.js
const getSpotifyToken = async () => {
  try {
    const response = await fetch('/api/spotify-auth');
    
    if (!response.ok) {
      const error = await response.json();
      if (response.status === 429) {
        throw new Error('Rate limit exceeded. Please try again later.');
      } else if (response.status === 403) {
        throw new Error('Unauthorized access.');
      } else {
        throw new Error(error.error || 'Failed to get Spotify token');
      }
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error('Error fetching Spotify token:', error);
    throw error; // Re-throw to handle in the component
  }
};

export default getSpotifyToken;