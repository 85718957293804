import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import { SpotifyLogo, AppleLogo, InstagramLogo, YoutubeLogo, FacebookLogo, XLogo, X } from '@phosphor-icons/react';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga4';
import './App.css';
import logo from './assets/logo.png';
import imageMobile from './assets/hs82-mobile-resized.png';

import YouTubeChannelVideos from './YouTubeChannelVideos';
import LatestSpotifyReleases from './LatestSpotifyReleases';
import ResidentAdvisorEventsGQL from './ResidentAdvisorEventsGQL';
import ContactInfo from './ContactInfo';

// Bind modal to app root
ReactModal.setAppElement('#root');

// Modal animation variants
const modalVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 0.3,
      duration: 0.5,
    },
  },
};

// Content animation variants
const contentVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 0.2,
    },
  },
};

// Close button animation variants
const closeButtonVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

// Main content animation variants
const mainContentVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0,
      ease: "easeIn",
    },
  },
};

// Main App Component
const App = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const baseTitle = 'Hot Since 82'
  const description = 'Official Hot Since 82 Website - Latest News, Music Releases, Tour Dates, and More';
  const currentUrl = 'https://www.hotsince82.com/';

  // Google Analytics Initialization
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: document.title });
    }
  }, []);

  // Track Page Views on Route Change
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  // Memoize navigation links
  const navigationLinks = useMemo(() => [
    { label: 'SHOWS', component: <ResidentAdvisorEventsGQL /> },
    { label: 'VIDEOS', component: <YouTubeChannelVideos /> },
    { label: 'MUSIC', component: <LatestSpotifyReleases /> },
    { label: 'CONTACT', component: <ContactInfo /> },
  ], []);

  // Memoize openModal and closeModal functions
  const openModal = useCallback((event, component, changeUrl = true) => {
    if (event) event.preventDefault();
    setModalContent(component);
    setModalOpen(true);

    if (changeUrl) {
      const newPath = navigationLinks
        .find((link) => link.component === component)
        .label
        .toLowerCase()
        .replace(/\s+/g, '-');
      navigate(`/${newPath}`);
    }
  }, [navigate, navigationLinks]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    navigate('/');
  }, [navigate]);

  // Handle initial content visibility with animation
  useEffect(() => {
    setContentVisible(true);
  }, []);

  // Handle modal state based on URL
  useEffect(() => {
    const modalPath = location.pathname.replace('/', '');
    if (modalPath) {
      const link = navigationLinks.find((link) => 
        link.label.toLowerCase().replace(/\s+/g, '-') === modalPath.toLowerCase()
      );
      if (link) {
        openModal(null, link.component, false);
      }
    }
  }, [location, navigationLinks, openModal]);


  // Function to lock scrolling when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isModalOpen]);

  // Handle Escape key press
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isModalOpen, closeModal]);

  return (
    <div className="content">

      <Helmet>
        <title>Hot Since 82</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={baseTitle} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={baseTitle} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>

      <motion.div 
        className="app"
        initial="hidden"
        animate={isContentVisible ? "visible" : "hidden"}
        variants={mainContentVariants}
      >
        <header>
          <h1 className="logo">
            Hot Since 82
            <img src={logo} alt="Logo" />
          </h1>
        </header>

        <div className="image-for-mobile">
          <img src={imageMobile} alt="Hot Since 82" />
        </div>

        <nav className="navigation">
          {navigationLinks.map((link, index) => (
            <button
              key={index}
              onClick={(e) => openModal(e, link.component)}
              className="nav-link"
            >
              {link.label}
            </button>
          ))}
        </nav>

        <footer>
          <div className="social-icons">
            <a href="https://music.apple.com/us/artist/hot-since-82/459095802" target="_blank" rel="noopener noreferrer">
              <AppleLogo size={40} weight="fill" />
              Apple Music
            </a>
            <a href="https://open.spotify.com/artist/1tRBmMtER4fGrzrt8O9VpS" target="_blank" rel="noopener noreferrer">
              <SpotifyLogo size={40} weight="fill" />
              Spotify
            </a>
            <a href="https://twitter.com/hotsince82" target="_blank" rel="noopener noreferrer">
              <XLogo size={40} weight="fill" />
              Twitter
            </a>
            <a href="https://www.facebook.com/hotsince82" target="_blank" rel="noopener noreferrer">
              <FacebookLogo size={40} weight="fill" />
              Facebook
            </a>
            <a href="https://www.instagram.com/hotsince82/" target="_blank" rel="noopener noreferrer">
              <InstagramLogo size={40} weight="fill" />
              Instagram
            </a>
            <a href="https://www.youtube.com/channel/UCVCDyug97fCruUeK250eTCg" target="_blank" rel="noopener noreferrer">
              <YoutubeLogo size={40} weight="fill" />
              Youtube
            </a>
          </div>
        </footer>

        <AnimatePresence>
          {isModalOpen && (
            <motion.div
              className="modal-overlay"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={modalVariants}
            >
              <motion.button
                onClick={closeModal}
                className="close-button"
                aria-label="Close modal"
                variants={closeButtonVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <X size={34} weight="regular" aria-hidden="true" />
              </motion.button>

              <motion.div className="modal" role="main" variants={contentVariants}>
                {modalContent}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default App;