import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';

const Root = () => (
  <Router>
    <Routes>
      <Route path="*" element={<App />} /> {/* All routes handled by App */}
    </Routes>
  </Router>
);

export default Root;