import React from 'react';
import { CircleNotch } from '@phosphor-icons/react';

const Spinner = () => {
  return (
    <div className="spinner-container">
      <CircleNotch size={32} weight="regular" className="spinner-icon" />
    </div>
  );
};

export default Spinner;