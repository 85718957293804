import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Spinner from './Spinner';
import getSpotifyToken from './spotifyAuth';
import ReactGA from 'react-ga4';

const LatestSpotifyReleases = () => {
  const artistId = '1tRBmMtER4fGrzrt8O9VpS';
  const cacheKey = 'spotifyReleasesCache';
  const cacheDuration = 15 * 24 * 60 * 60 * 1000;

  const [releases, setReleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  const baseTitle = 'Music | Hot Since 82'
  const description = 'Stream the latest releases from Hot Since 82 - featuring new singles, remixes, and albums on major streaming platforms.';
  const currentUrl = 'https://www.hotsince82.com/music';

  useEffect(() => {
    ReactGA.event({
      category: 'Modal',
      action: 'Opened Spotify Releases Modal',
      label: 'Spotify Modal',
    });
  }, []);

  useEffect(() => {
    const fetchReleases = async () => {
      try {
        // Check cached data first
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          const isCacheValid = Date.now() - parsedData.timestamp < cacheDuration;

          if (isCacheValid) {
            setReleases(parsedData.releases);
            setLoading(false);
            return;
          }
          localStorage.removeItem(cacheKey);
        }

        // Get new Spotify token
        const token = await getSpotifyToken();
        if (!token) {
          throw new Error('Failed to obtain Spotify access token');
        }

        // Fetch albums with error handling
        const albumsResponse = await axios.get(
          `https://api.spotify.com/v1/artists/${artistId}/albums`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              limit: 20,
              include_groups: 'album,single',
              market: 'US',
            },
          }
        ).catch(error => {
          if (error.response?.status === 401) {
            throw new Error('Spotify authentication failed');
          }
          throw error;
        });

        const albums = albumsResponse.data.items;

        // Fetch album details with proper error handling
        const albumDetailsPromises = albums.map(async (album) => {
          try {
            const albumDetailsResponse = await axios.get(
              `https://api.spotify.com/v1/albums/${album.id}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            return {
              ...album,
              label: albumDetailsResponse.data.label,
            };
          } catch (error) {
            console.error(`Failed to fetch details for album ${album.id}:`, error);
            return {
              ...album,
              label: 'Label information unavailable',
            };
          }
        });

        const albumDetails = await Promise.all(albumDetailsPromises);
        const sortedReleases = albumDetails.sort(
          (a, b) => new Date(b.release_date) - new Date(a.release_date)
        );

        // Cache the results
        const dataToCache = {
          releases: sortedReleases,
          timestamp: Date.now(),
        };
        localStorage.setItem(cacheKey, JSON.stringify(dataToCache));

        setReleases(sortedReleases);
        setLoading(false);
        setError('');
      } catch (err) {
        console.error('Failed to fetch releases:', err);
        
        // Handle specific error cases
        if (err.message.includes('Rate limit exceeded')) {
          setError('Too many requests. Please try again in a minute.');
        } else if (err.message.includes('Unauthorized')) {
          setError('Authentication error. Please refresh the page.');
        } else {
          setError('Failed to load releases. Please try again later.');
        }

        // Use cached data as fallback if available
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          setReleases(parsedData.releases);
          setError('Using cached data. ' + err.message);
        }

        setLoading(false);
      }
    };

    // Only retry a maximum of 3 times
    if (retryCount < 3) {
      fetchReleases();
    }
  }, [artistId, retryCount]);

  // Add retry functionality
  const handleRetry = () => {
    setLoading(true);
    setError('');
    setRetryCount(prev => prev + 1);
  };

  if (loading) {
    return <div><Spinner /></div>;
  }

  return (
    <div className="releases-modal max-width">
      <Helmet>
        <title>{baseTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={baseTitle} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={baseTitle} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>

      <h2>Latest Releases</h2>
      {error && (
        <div className="error-message">
          <p>{error}</p>
          {retryCount < 3 && (
            <button onClick={handleRetry} className="retry-button">
              Try Again
            </button>
          )}
        </div>
      )}
      
      {releases.length > 0 ? (
        <ul>
          {releases.map((release) => (
            <li key={release.id}>
              <a
                href={release.external_urls.spotify}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="left">
                  <img
                    src={release.images[0]?.url || 'https://via.placeholder.com/240'}
                    alt={release.name}
                    width="240"
                    height="240"
                  />
                </div>
                <div className="right">
                  <h3>{release.name}</h3>
                  <span>{release.label} · {new Date(release.release_date).getFullYear()}</span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      ) : !error && (
        <p>No releases found</p>
      )}

      <a 
        href={`https://open.spotify.com/artist/${artistId}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="bottom-link"
      >
        View more releases
      </a>
    </div>
  );
};

export default LatestSpotifyReleases;