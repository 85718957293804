import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './Root'; // Import the Root component
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4'; // Import react-ga4

// Initialize Google Analytics only in production
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root /> {/* Render the Root component which includes Router and App */}
  </React.StrictMode>
);

// Optional: Track initial page load
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();