import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PaperPlaneTilt } from '@phosphor-icons/react';
import ReactGA from 'react-ga4';

const ContactInfo = () => {

  const baseTitle = 'Contact | Hot Since 82'
  const description = 'Get in touch with Hot Since 82 team for bookings and management enquiries.';
  const currentUrl = 'https://www.hotsince82.com/contact';

  // Track modal open event with Google Analytics
  useEffect(() => {
    ReactGA.event({
      category: 'Modal',
      action: 'Opened Contact Modal',
      label: 'Contact Modal',
    });
  }, []);

  return (
    <div className="contact-modal max-width">

      <Helmet>
        <title>{baseTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={baseTitle} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={baseTitle} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>

      <h2>Contact</h2>
      <ul>
        <li>
          <div className="contact-item">
            <h3>Management</h3>
            <h4>James Drummond</h4>
            <div className="email">
              <span className="icon"><PaperPlaneTilt size={20} weight="bold" color="#ccc" /></span>
              <a href="mailto:jamesd@anglomanagement.co.uk" target="_blank" rel="noopener noreferrer">
                <span className="only-mobile">Email</span><span className="email-handle">jamesd@anglomanagement.co.uk</span>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div className="contact-item">
            <h3>Booking</h3>
            <h4>Europe / ROW</h4>
            <div className="email">
              <span className="icon"><PaperPlaneTilt size={20} weight="bold" color="#ccc" /></span>
              <a href="mailto:SHogan@WMEAgency.com" target="_blank" rel="noopener noreferrer">
                <span className="only-mobile">Steve Hogan</span><span className="email-handle">SHogan@WMEAgency.com</span>
              </a>
            </div>
            <h4>North / South America</h4>
            <div className="email">
              <span className="icon"><PaperPlaneTilt size={20} weight="bold" color="#ccc" /></span>
              <a href="mailto:andrew@liaisonartists.com" target="_blank" rel="noopener noreferrer">
                <span className="only-mobile">Andrew Kelsey</span><span className="email-handle">andrew@liaisonartists.com</span>
              </a>
            </div>
            <div className="email">
              <span className="icon"><PaperPlaneTilt size={20} weight="bold" color="#ccc" /></span>
              <a href="mailto:mariesa@liaisonartists.com" target="_blank" rel="noopener noreferrer">
                <span className="only-mobile">Mariesa Stevens</span><span className="email-handle">mariesa@liaisonartists.com</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ContactInfo;