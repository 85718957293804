import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Play, YoutubeLogo } from '@phosphor-icons/react';
import Spinner from './Spinner';
import ReactGA from 'react-ga4';

const formatViewCount = (viewCount) => {
  if (!viewCount) return '0';
  if (viewCount >= 1000000) return (viewCount / 1000000).toFixed(1) + 'M';
  if (viewCount >= 1000) return (viewCount / 1000).toFixed(1) + 'k';
  return viewCount;
};

const decodeHtmlEntities = (text) => {
  if (!text) return '';
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};

const YouTubeChannelVideos = () => {
  const channelId = 'UCVCDyug97fCruUeK250eTCg';
  const cacheKey = 'youtubeChannelVideosCache';
  const cacheDuration = 7 * 24 * 60 * 60 * 1000;

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [views, setViews] = useState({});

  const baseTitle = 'Videos | Hot Since 82'
  const description = 'Check all the most recent Hot Since 82 videos.';
  const currentUrl = 'https://www.hotsince82.com/videos';

  useEffect(() => {
    ReactGA.event({
      category: 'Modal',
      action: 'Opened YouTube Channel Videos',
      label: 'YouTube Modal',
    });
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          if (parsedData && Date.now() - parsedData.timestamp < cacheDuration) {
            setVideos(parsedData.videos || []);
            setViews(parsedData.views || {});
            setLoading(false);
            return;
          }
          localStorage.removeItem(cacheKey);
        }

        const response = await fetch('/api/youtube-videos');
        if (!response.ok) throw new Error('Failed to fetch videos');
        
        const data = await response.json();
        
        if (!data || !data.videos) {
          throw new Error('Invalid data format received');
        }

        // Create views object with safety checks
        const viewsData = {};
        data.videos.forEach(video => {
          if (video && video.id && video.id.videoId) {
            viewsData[video.id.videoId] = video.statistics?.viewCount || '0';
          }
        });

        const validVideos = data.videos.filter(video => 
          video && video.id && video.id.videoId && video.snippet
        );

        localStorage.setItem(cacheKey, JSON.stringify({
          videos: validVideos,
          views: viewsData,
          timestamp: Date.now(),
        }));

        setVideos(validVideos);
        setViews(viewsData);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError('Failed to fetch videos. Please try again later.');
      }
      setLoading(false);
    };

    fetchVideos();
  }, [cacheDuration]);

  if (loading) return <div><Spinner /></div>;
  if (error) return <p>{error}</p>;

  return (
    <div className="videos-modal max-width">

      <Helmet>
        <title>{baseTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={baseTitle} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={baseTitle} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>

      <h2>Recent Videos</h2>
      {videos && videos.length > 0 ? (
        <ul>
          {videos.map(video => {
            if (!video || !video.id || !video.id.videoId || !video.snippet) return null;
            
            return (
              <li key={video.id.videoId}>
                <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
                  <div className="left">
                    <img 
                      src={video.snippet.thumbnails?.medium?.url || ''} 
                      alt={decodeHtmlEntities(video.snippet.title)} 
                      width="240" 
                      height="135"
                    />
                    <span className="word-play">
                      <Play size={18} weight="regular" color="#fff" />
                      Play
                    </span>
                  </div>
                  <div className="right">
                    <p>{decodeHtmlEntities(video.snippet.title)}</p>
                    <span className="views">
                      <YoutubeLogo size={18} />
                      {views[video.id.videoId] ? formatViewCount(views[video.id.videoId]) : '0'} views
                    </span>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      ) : (
        <p>No videos available</p>
      )}
      <a 
        href={`https://www.youtube.com/channel/${channelId}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="bottom-link"
      >
        View more videos
      </a>
    </div>
  );
};

export default YouTubeChannelVideos;