import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MapPin, Equalizer, UsersThree, Ticket } from '@phosphor-icons/react';
import Spinner from './Spinner';
import ReactGA from 'react-ga4';

const LOCAL_STORAGE_KEY = 'ra_events_hs82_cache';
const LOCAL_CACHE_DURATION = 3600 * 24; // 24 hours in seconds

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return {
    month: date.toLocaleString('en-GB', { month: 'short' }),
    day: date.getDate().toString().padStart(2, '0'),
    year: date.getFullYear()
  };
};

const ResidentAdvisorEventsGQL = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const baseTitle = 'Shows | Hot Since 82';
  const description = 'Find upcoming shows and events of Hot Since 82.';
  const currentUrl = 'https://www.hotsince82.com/shows';

  useEffect(() => {
    ReactGA.event({
      category: 'Modal',
      action: 'Opened Resident Advisor Events Modal',
      label: 'RA Events Modal',
    });
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Check local storage first
        const cached = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < LOCAL_CACHE_DURATION * 1000) {
            setEvents(data);
            setLoading(false);
            return;
          }
          localStorage.removeItem(LOCAL_STORAGE_KEY);
        }

        // If no valid cache, fetch from API
        const response = await fetch('/api/ra-events-gql');
        if (!response.ok) throw new Error('Failed to fetch events');
        const data = await response.json();
        
        // Update local storage
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
          data,
          timestamp: Date.now()
        }));
        
        setEvents(data);
      } catch (err) {
        setError('Failed to fetch events.');
        console.error('Error fetching events:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // Rest of the component remains the same
  if (loading) return <Spinner />;
  if (error) return <p>{error}</p>;

  return (
    <div className="gigs-modal max-width">
      <Helmet>
        <title>{baseTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={baseTitle} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={baseTitle} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>

      <h2>Upcoming shows</h2>
      <ul>
        {events.length === 0 ? (
          <p>No upcoming shows found.</p>
        ) : (
          events.map((event) => {
            const { month, day, year } = formatDate(event.date);
            
            return (
              <li key={event.id}>
                <div className="left">
                  <div className="gig-item">
                    <div className="date-container">
                      <span className="date-month">{month}</span>
                      <span className="date-day">{day}</span>
                      <span className="date-year">{year}</span>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="gig-item">
                    <h3>{event.title}</h3>
                  </div>

                  <div className="gig-item">
                    <span className="icon">
                      <MapPin size={18} weight="bold" color="#ccc" />
                    </span>
                    <p>{event.venue} - {event.city}</p>
                  </div>

                  <div className="gig-item">
                    <span className="icon">
                      <Equalizer size={18} weight="bold" color="#ccc" />
                    </span>
                    <p>
                      {event.lineup.map((artist, index) => (
                        <React.Fragment key={artist}>
                          {artist}
                          {index < event.lineup.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                    </p>
                  </div>

                  <div className="gig-item">
                    <span className="icon">
                      <UsersThree size={18} weight="bold" color="#ccc" />
                    </span>
                    <p>{event.interested} people interested</p>
                  </div>

                  <a 
                    className="buy-link" 
                    href={event.link}
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Ticket size={18} weight="regular" />
                    Buy Tickets on RA
                  </a>
                </div>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default ResidentAdvisorEventsGQL;